import { memo, useEffect, useRef, useState } from 'react';
import { autoInit } from 'pym.js';

import { useIntersectionObserver } from '@hubcms/utils-browser';

import { HtmlEmbedProps } from './types';

const HtmlEmbed = memo(({ htmlBlock, className, style }: HtmlEmbedProps) => {
  const divRef = useRef<HTMLDivElement>(null);
  const [isInitialized, setIsInitialized] = useState(false);
  const ioEntry = useIntersectionObserver(divRef, {
    rootMargin: '-50px 0px 0px',
    threshold: 0.1,
  });

  useEffect(() => {
    if (!htmlBlock || !divRef.current) {
      return;
    }

    // Put data inside shadow dom if it's a whole html document
    // Using [\s\S] instead of . matches multiple lines between the tags
    if (/<html.*>[\s\S]*<\/html>$/m.test(htmlBlock)) {
      const shadow = divRef.current.attachShadow({ mode: 'open' });
      shadow.innerHTML = htmlBlock;
      setIsInitialized(true);
      return;
    }

    const slotHtml = document.createRange().createContextualFragment(htmlBlock); // Create a 'tiny' document and parse the html string

    const iframes = slotHtml.querySelectorAll<HTMLIFrameElement>('iframe[data-allow-resize]');
    iframes.forEach(iframe => {
      const iframeSrc = iframe.getAttribute('src') || iframe.getAttribute('data-src') || '';
      const iframeStyle = iframe.style;
      const iframeClassName = iframe.className;
      const parentNode = iframe.parentNode;
      const iframeContainer = document.createElement('div');
      iframeContainer.setAttribute('data-pym-src', iframeSrc);
      if (iframeStyle) {
        iframeContainer.style.cssText = iframeStyle.cssText;
      }
      if (iframeClassName) {
        iframeContainer.className = iframeClassName;
      }

      if (parentNode) {
        parentNode.appendChild(iframeContainer);
        parentNode.removeChild(iframe);
      } else {
        slotHtml.appendChild(iframeContainer);
        slotHtml.removeChild(iframe);
      }
    });

    divRef.current.innerHTML = ''; // Clear the container
    divRef.current.appendChild(slotHtml); // Append the new content
    setIsInitialized(true);
  }, [htmlBlock, divRef]);

  useEffect(() => {
    if (ioEntry?.isIntersecting && isInitialized) {
      autoInit(false);
    }
  }, [ioEntry?.isIntersecting, isInitialized]);

  if (!htmlBlock) {
    return null;
  }

  return <div className={className} style={style} ref={divRef} />;
});

export default HtmlEmbed;
